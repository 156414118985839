<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">{{details.table_name}} </span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="details">
            <tbody>
                <tr v-for="(v, index) in details.fields" :key="index">
                    <th>{{ v.name }}</th>
                    <td>{{ v.value }}</td>
                </tr>
            </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'TableRateEdit', params: { product: $route.params.product, insurer: $route.params.insurer, id: $route.params.id, rateid: details.id  } }" variant="outline-dark" class="float-right">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'Rate',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Rate Details'
      },
    },
    data: () => {
      return {
        details: {
         code: "",
         title: "",
         id: ""
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getData() {
        let url = '';

        url = '/rates/'+ this.$route.params.rateid + '/rate/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer;

        axios.get(url).then(
          response => {
            this.details = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.details
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    mounted() {
      this.getData()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getData()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
